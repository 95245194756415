import React from 'react';
import "./postStyles.css";
import GB from "../assets/gb.mp4";
import { Button } from 'antd';
import { useNavigate  } from 'react-router-dom';
import Header from './Header';


function PostContent() {
  const naviagtion = useNavigate()
const openCourses = ()=>{
naviagtion("/courses")
}
  return (
    <div>
      <Header className="story_header"/>
    <div className='postcontent-div'> 

{/* Video header section */}
<div className="video-container">
  <video className="background-video" autoPlay loop muted playsInline>
    <source src={GB} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <div className="overlay3">
    
    <div className='spacer'>
    
    </div>
    <div>
      <h1 className='ms-title'>My Story</h1>
     <p className='overlay-text'>Let’s turn your curiosity into confidence</p>
    <p className='overlay-text2'> Gain valuable skills, and take the next step in your journey as a developer.</p>
    </div>
  

  </div>
</div>

{/* Content section below the video header */}
<div className="inner-postcontent">
  {/* Content goes here */}
  <div className='mystory-text'>
    <p className='mst-ptags'>
    Hello 👋, I’m Muneza Dixon
    <br/>
    <br/>

I often say that code saved my life. Without it, I could easily have ended up lost, or maybe worse from an overdose or just another junkie, wandering the streets of Kampala looking for the next high. But thankfully, my path changed.
<br/>
<br/>

Growing up in a family of six, I was raised by a single mother after we lost my dad when I was barely 13. My siblings were brilliant, full of potential, while I often felt like the odd one out. I was different, and alot of people, including my family members didn’t hesitate to remind me of that. I left from home really early, i grew up so fast , and found myself with the “misfits” of society(at least thats what they called them) those considered hoodlums. I had nothing on me but a pair of army shorts and an oversized jacket. But these people took me in anyway, a total stranger even if i was just a kid. They  became my new family and took care of me. My bed was a one inc matteress, and no covers aprt from my jacket...am writing this and i cant believe i never really thought about what it made feel.  
<br/>
<br/>
In time, I fell into a life of drugs, battling deep feelings of emptiness, anxiety and depression. I was lost, unsure of my purpose. But then came my first "real" encounter with technology, and it changed everything about i saw the world.
<br/>
<br/>
One of my friends back there, "Vanic," owned a laptop and a smartphone. By that time I had never seen these devices up close before. The sleek design, the animations, the themeing was amazing, the large app icons—all of it captivated me. And i was filled with questions: how were these devices made? How did they work? I told anyone who would listen that I wanted to build something like that—technology to help others. That was crazy back there because no one dared to dream back in the hood. You do what you're told and that's It.
    <br/>
    <br/>

Eventually, I saved up for a Nokia C3 phone through ways I would prefer not to mention here, and used it to start learning. I found PDFs on Java programming and took notes every chance i got, my note books filled up really fast so i ended up writing code everywhere i could...the walls, old newspaper sides.... Vanic let me use his laptop, and I now was able to practice on a real computer and finally i built my first application, called <a style={{color:"blue", fontStyle:"italic"}} href='https://steprimo.com/android/en/app/com.digitalminds.android.nusu/'>Nusu,</a> I have rebulit it over the years until 4 years back. It was a simple tool to help my friends track their spending, as financial management wasn’t their strong suit. Creating that started something in me, a purpose. I finally knew how to make it work, though i needed to learn more: to create software that can change the world and our people, and to bring these skills back to the hood to help others find purpose too.
<br/>
<br/>

Soon, Vanic realized I could do more than watch movies on his laptop. He started telling people in the neighborhood about me, and I began assembling computers and writing scripts that enabled them to "borrow" things like Cable TV and WiFi access for his friends. I didn’t charge for these jobs—the thrill of building something from scratch was enough for me. This work gave me a deep love to further study technology, not just as a tool, but as a force that could change lives and communities.

<br/>
<br/>
In the tech industry, one of the people who truly inspired me was William, the CEO of a startup called Willapps. He’s still one of my favorite people to this day. Before meeting him, I never thought I could have a career in technology. Coding was just a hobby—a personal passion, not something I imagined could become a career or a source of income.<br/>
<br/>
William gave me my first job in tech back in 2017, and I learned so much from him. To this day, I still call him laoshi, which means "teacher" in Chinese. His mentorship opened doors for me that I hadn't even realized were possible    </p>
<h3 className='why-cwd mst-ptags'>Why codeWithDixon</h3>
<p className='mst-ptags'>
For the past 9 years, i have been learning how some of the technology out here works, reverse engineered some of it, writtng code more than 18hours of everyday until now, and its through this learning, I created CodeWithDixon to give young people in Uganda what I wish I had: practical, real-world coding skills that prepare them for careers in technology. One of my friends Assan graduated from university with a degree in computer science, but like so many, he left school with knowledge that didn’t translate into real skills. Today, he’s working as a salesman, and may like him face the same situation.
<br/>
<br/>

CodeWithDixon is my answer to this problem. Here, students get one-on-one mentorship and hands-on experience in coding, not just theory. By building practical skills, we empower aspiring developers to enter the workforce with confidence and real ability.
<br/>
<br/>

My story is proof that you as well can have a career in technology, with the right skills, support and people. With CodeWithDixon, I hope to offer that chance to others, creating a community of tech talent ready to make a difference in Uganda.
<br/>
<br/>
<br/>
</p>
  </div>
  <div className='cta-ms'>
  <div className='cta-div'>
<p className='cta-text'>
Ready to start your own journey in tech? Join CodeWithDixon and gain the skills, mentorship, and support to build the future you deserve. Let’s get started!
</p>
<button onClick={openCourses} className='vc-btn'>Explore Courses!</button>
</div>
<div className='cta-div2'>

</div>

  </div>
</div>
</div>
    </div>
  );
}

export default PostContent;
