import React, { useState } from "react";
import "./header.css";
import {
  useScroll,
  useAnimatedValue,
  AnimatedBlock,
  interpolate,
} from "react-ui-animate";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { Button, Popover, Space } from "antd";
import Logo from "../assets/logo.svg";
import { MenuOutlined } from '@ant-design/icons';
import "../mobileView.css"
import Nyx from "nyx-drawer-menu"

function Header() {

  const y = useAnimatedValue(0, { immediate: true });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const navigate = useNavigate();
  useScroll(({ scrollY }) => {
    y.value = scrollY;
  });

  const content = (
    <div>
      <div></div>
      <Link to="/mystory">
        <p>My Story</p>
      </Link>
      <Link to="/about-cwd">
        <p>About CodeWithDixon</p>
      </Link>
    </div>
  );

  const boxShadow = interpolate(
    y.value,
    [0, 400],
    ["0px 0px 0px rgba(0,0,0,0.2)", "0px 4px 20px rgba(0,0,0,0.2)"],
    {
      extrapolate: "clamp",
    }
  );

  const imageSize = interpolate(y.value, [0, 25], [100, 50], {
    extrapolate: "clamp",
  });

  return (
    <div className="header_div">
      <AnimatedBlock
        className="blockHeader"
        style={{
          backgroundColor: "#ffffff",
          display: "flex",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: "12rem",
          paddingRight: "12rem",
          position: "fixed",
          width: "100%",
          boxShadow,
        }}
      >
        <AnimatedBlock className="logo_holder">
          <Link to="/">
            <img className="logo" src={Logo} />
          </Link>
          <div className="button_holder">
          <Link to="/">

            <button className="header_btn">Home</button>
            </Link>

            <Space>
              <Popover
                placement="bottom"
                title={""}
                trigger="click"
                content={content}
              >
                <button className="header_btn">About</button>
              </Popover>
            </Space>
            <Link to="/courses">
              <button className="header_btn">Courses</button>
            </Link>
          </div>
        </AnimatedBlock>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 20,
            marginLeft: 20,
            flex: 1,
            color: "#353535",
          }}
        >
          {/* Header Title */}
        </div>
        <div className="contact-header">
          <i class="fi fi-sr-phone-rotary"></i>
          <p>Reach Out: (+256)-750-323-993</p>
        </div>
        <a href="https://mdixon.vercel.app/apps" target="__blank">
          <div
            style={{ color: "#8da5fe", cursor: "pointer" }}
            className="login_header"
          >
            <h4 className="font-bold whiteMe">
              <button className="header_btn">Portfolio</button>
            </h4>
            {/* <i id='ic' className='icon fi fi-rr-arrow-right'></i> */}
          </div>
        </a>
      </AnimatedBlock>
      <div className="mobile-menu">
      <Link to="/">
            <img className="logo" src={Logo} />
           
          </Link>
          <div className="menu-mobile-icon">
              <MenuOutlined
              onClick={toggleDrawer} 
              className="mobile-ic"/>
            </div>
            <Nyx isOpen={isDrawerOpen} onClose={toggleDrawer} width="300px" position="left">
        <h2>Menu</h2>
<Link className="link-style" to="/">
<p className="mobile-menu-text">Home</p>

</Link>
<Link className="link-style"  to="/mystory">
      
        <p className="mobile-menu-text">My Story</p>
        </Link>
        <Link className="link-style" to="/courses">

        <p className="mobile-menu-text">Courses</p>
        </Link>

        <Link className="link-style" to="/about-cwd">
    
        <p className="mobile-menu-text">About cwd</p>
        </Link>
<a href="https://mdixon.vercel.app/apps" target="_blank" className="mobile-menu-text">My Work</a>
      </Nyx>
      </div>

    </div>
  );
}

export default Header;
