import React from 'react'
import "./about.css"
import Header from './Header'
import Footer22 from './Footer22'
import { Divider, List, Typography } from 'antd';

function About() {



    const data = [
        'One-on-One Mentorship: I provide personalized attention to each student, ensuring they learn at a pace that suits them.',
        'Flexible Learning Options: Whether you prefer to learn remotely or meet in person',
        'Real-World Skills: Our program goes beyond theory, focusing on the practical, job-ready skills that employers value.',
      ];

  return (
    <div className='about-page'>
<Header/>
<div className='about-content'>
    <h1 className='ab-title'>About codewithdixon</h1>

    <p className='ab-text'>
    CodeWithDixon is a unique learning platform dedicated to providing personalized, one-on-one mentorship in web development and cloud technology to students across Uganda. I believe that every learner deserves the opportunity to gain real-world, practical skills that open doors in the tech industry.<br/> Through our flexible learning options—whether remote or in-person I aim to make quality tech education accessible to everyone with a passion for coding.
    </p>

    <div className='ab-section'>
    <div className='ab-image'></div>
    <div className='more-ab'>
        <h2 className='ab-title'>The Goal</h2>
    <p className='ab-text'>
    My goal is to close the skills gap by equipping students with hands-on experience in the most sought-after technologies, accessible to anyone regardless of their background.
    <br/> 
    <br/> 
    From building responsive web applications to understanding cloud deployment and DevOps, I ensure that our courses are designed for universal access and that our students learn the tools and techniques that matter most in today’s digital landscapeskills gap by equipping students with hands-on experience in the most sought-after technologies.
</p>
    </div>

    </div>
<div className='extras'>
<h2 className='ab-title'>The Offer</h2>

<p className='extras-text'>
At CodeWithDixon, students dive into essential technologies like React, Node.js, MongoDB, Firebase, AWS, and more. By focusing on real-world projects, I teach not only the fundamentals of coding but also how to think critically, troubleshoot effectively, and work independently on complex tech solutions. With this approach, we’re building a new generation of Ugandan tech talent that’s ready to meet the challenges of a competitive global market.</p>
<h2 className='ab-title'>Why Choose CodeWithDixon?</h2>
{/* <h5 className='ab-set-apart'>Our approach sets us apart:</h5> */}
<Divider orientation="left">Our approach sets us apart</Divider>
    <List
      size="small"
      bordered
      dataSource={data}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
</div>
</div>
<Footer22/>
    </div>
    
  )
}

export default About