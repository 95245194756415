import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDqJacHOG3uIBwg05drxTsLyxsTHIooHkQ",
    authDomain: "navisimages.firebaseapp.com",
    projectId: "navisimages",
    storageBucket: "navisimages.appspot.com",
    messagingSenderId: "184005487067",
    appId: "1:184005487067:web:4329b2f5f21ef5ce19dffe",
    measurementId: "G-8XT2PH65FV"
  };

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage, ref, uploadBytes, getDownloadURL };
