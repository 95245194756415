import React from 'react'
import "./mystory.css"
import Header from './HeaderMobile.js'
import Footer22 from "./Footer22"
import PostContent from "./PostContent.js"

function MyStory() {
  return (
    <div className='mystory-page'>
        <Header className="ms-header"/>
        <div className='content-home'>
        <PostContent/>  
      </div>  
        <Footer22/>
    </div>
  )
}

export default MyStory