import React, { useEffect, useState } from 'react';
import './topcontent.css';
import Map from '../assets/hero_img.png';
import Cubes from './Cubes';
import Drivers from '../assets/node.jpg';
import RoutesImage from '../assets/react.jpg';
import Deliveries from '../assets/mongo.jpg';
import Map_ from "../assets/sk.jpg"
import Map_2 from "../assets/moxie2.jpg"
import OP from "../assets/on.jpg"
import ANA from "../assets/impact.jpg"
import Lady from "../assets/ddd.jpg"
import Lady2 from "../assets/dd2.jpg"
import Del from "../assets/fo.jpg"
import TruckerMap from "../assets/ship.png"
import {useNavigate} from "react-router-dom"

import Footer from './Footer';
import Footer22 from './Footer22';

function TopContent() {
  const [bgColor, setBgColor] = useState('rgba(255, 255, 255, 1)');
  const [lineHeight, setLineHeight] = useState(0); 
  const [ballPosition, setBallPosition] = useState(0); 
  const navigation = useNavigate();

  //handleScroll ball 
  const handleScroll = () => {
    const section = document.querySelector('.hero_section3');
    const rect = section.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    
    //total height of the scrollable section
    const sectionTop = rect.top + window.scrollY;
    const sectionHeight = rect.height;
    const scrollableHeight = sectionHeight + windowHeight;
  
    // scroll progress as a percentage of the scrollable area
    const scrollTop = window.scrollY;
    const scrollProgress = Math.min((scrollTop - sectionTop + windowHeight) / scrollableHeight, 1);
    if (rect.top <= windowHeight && rect.bottom >= 0) {
      const progress = Math.min((windowHeight - rect.top) / windowHeight, 1);
    // Update background color based on scroll progress
    const r = Math.round(255 + progress * (17 - 255));
    const g = Math.round(255 + progress * (33 - 255));
    const b = Math.round(255 + progress * (64 - 255));
    const color = `rgb(${r}, ${g}, ${b})`;
    setBgColor(color);
    }
    // Update line height to scroll fully to the end of the section
    const maxLineHeight = sectionHeight; // Full height of the section
    setLineHeight(scrollProgress * maxLineHeight);
  
    // Update ball position to move proportionally with the line
    const maxBallPosition = sectionHeight - 10; // Maximum position of the ball
    setBallPosition(scrollProgress * maxBallPosition);
  };
  

  const start = ()=>{
navigation("/courses")
  }

  const sales = ()=>{
    navigation("/courses")
      }

      const mystory = ()=>{
        navigation("/mystory")
          }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll); // Cleanup
  }, []);

  return (
    <div className='top-content2' style={{ backgroundColor: bgColor }}>
      <div className='hero_section'>
        <div className='text_hero'>
          <h1 className='f-text'>Learn Web Development Your Way!</h1>
          <p className='boost'>
          Whether you're learning remotely or in-person, I’ll help you build real-world skills in React, Node.js, MongoDB, and more using personalized individual lessons designed to match your learning style and pace.          </p>
          <div className='btnArea'>
            <button onClick={mystory} className='newBtn resizebtn'>My Story</button>
            <button onClick={sales} className='newBtn resizebtn2 contact'>Get Started</button>
          </div>
        </div>
        <div className='imageside'>
          {/* <img className='hero_image' src={Map}/> */}
        </div>
      </div>
      {/* lower div */}
      <div className='tw-gradient'>
<div className='tw-gradient-top' >
  <div className='twg-txt'>
<p className='ai-powered-text'>One-on-One Coding Mentorship from Dixon.</p>
<p className='ai-powered-text2'>Improve Your Developer Skills Today! </p>
  </div>
  <div className='tw-gradient-inner-top'>
<div className='twg-div1'>
  <h4 className='twg-title'>Empowering Aspiring Developers in Uganda </h4>
  <h4 className='twg-subTitle'>Gain practical experience</h4>
  <p className='twg-p'>
  Through CodeWithDixon, you’ll work with me directly to gain practical experience in today’s essential technologies and DevOps tools you need to manage and deploy real projects. 
    </p>
  <button className='twg-btn'>Learn More</button>
  </div>


  <div className='twg-images'> 

<img className='twg-image-one' alt='muneza dixon' src={Lady}/>
<img className='twg-image-one2' alt='muneza dixon' src={Lady2}/>

  </div>
  </div>

</div>
</div>
      <div className='hero_section2'>
        <div className='items_section'>
          <div className='item_holder'>
            <p className='why-us'>What will I Learn?</p>
            <h3 className='why_sub'>The program is not just to teach coding, but also a deep dive into how technology actually works. </h3>
            <div className='cubes'>
              <Cubes
                image={RoutesImage}
                desc={'Gain expertise in writing efficient code for cross-platform apps, leveraging frameworks and tools to create seamless experiences across devices like PCs & mobile phones.'}
                title={'Programming Languages'}
              />
              <Cubes
                image={Drivers}
                desc={'Understand the building blocks of technology, from how computers process information to the fundamentals of networks, operating systems, and data management.'}
                title={'Computing 101'}
              />
              <Cubes
                image={Deliveries}
                desc={'Learn the essential tools developers use to build, debug, and optimize software. From version control systems like Git to IDEs, that make software development efficient.'}
                title={'Dev Tools'}
              />
            </div>
          </div>
        </div>
      </div>
      {/* White line that moves as we scroll */}
      <div className='map_drivers_title'>
<h4 className='how_txt'>Welcome To CodeWithDixon</h4>
<h4 className='sync_txt'>Where Personalized Learning Meets Real-World Skills.</h4>

      </div>
      <div className='hero_section3' style={{ backgroundColor: bgColor }}>
      <div className='right_map2'>
      <img className='manage_drivers' src={Map_} />    

<img className='manage_drivers' src={Map_2} />    

      </div>

        <div className='white-line-container'>
        
          <div className='white-line' style={{ height: `${lineHeight}px` }}></div>
          <div className='circle' style={{ top: `${ballPosition}px` }}></div>    
        </div>

        <div className='right_map'>
          <div className='right_map_content'>
<h4 className='track-title'>Your Personalized Learning Journey Starts Here</h4>
         <p className="am-desc">
         Imagine learning coding skills in a way that’s completely customized to your strengths, goals, and schedule. With One-on-One Coding Mentorship, you’ll work directly with an experienced instructor who tailors every lesson to fit your personal journey.           </p>
         <div className='trm'>
         <i style={{color:"#e24e90"}} className='icon' id='ic_trm' class="fi fi-bs-car-journey"></i>
<div className='trm_txt'>
  <p className='trm_title'>Skill-Based Learning Pathways</p>
  <p className='trm_desc'>
  Select a learning pathway that matches your career aspirations. CodeWithDixon gets from where ever you are, whether you an experienced developer or just a beginner there's always soemthing to learn.    </p>
</div>
         </div>

         <div className='trm'>
         <i style={{color:"gold"}} className='icon' id='ic_trm' class="fi fi-sr-messages"></i>
<div className='trm_txt'>
  <p className='trm_title'>Flexible Scheduling and Learning Options</p>
  <p className='trm_desc'>
  Whether you’re balancing a job or other commitments, the flexible scheduling allows you to set up lessons at times that suit you. You can choose to learn remotely from home or attend sessions in person. With options to switch between formats, you get a seamless, flexible learning experience.    </p>
</div>
         </div>

          </div>
          <div className='right_map_content'>
            
<h4 className='track-title'>Career-Ready Project Building</h4>
         <p className="am-desc">
         CodeWithDixon emphasizes practical skills that directly prepare you for the job market. You’ll work on real-world projects that reflect the demands of the tech industry, creating a strong portfolio to demonstrate your abilities to employers.          </p>
         <div className='trm'>
         <i style={{color:"lime"}} className='icon' id='ic_trm' class="fi fi-bs-pencil"></i>
<div className='trm_txt'>
  <p className='trm_title'>Portfolio-Ready Projects</p>
  <p className='trm_desc'>
  In this track, you’ll work on a series of projects that demonstrate your expertise in both frontend and backend development. From responsive websites to dynamic web applications, each project is designed to highlight your problem-solving skills and your understanding of essential technologies.    </p>
</div>
         </div>
          </div>
          

        </div>
      </div>
      <div className='testHolder'>
      <Cubes desc={"Unlike typical classroom-based or large-scale online courses, CodeWithDixon offers personalized, one-on-one mentorship. Each student gets the attention they need, tailored to their own learning style, pace, and goals. meaning generic curriculum—every session is specifically designed for the individual."} title={"One-on-One Mentorship"} image={OP}/>
        <Cubes desc={"Students don’t just learn theory; they work on real-world projects that they can showcase to potential employers. With hands-on experience in popular tools and frameworks like React, Node.js, MongoDB, Firebase, AWS, and more, students gain the practical skills needed to succeed in the tech industry."} title={"Focused on Career-Readiness"} image={Del}/>
        <Cubes desc={"CodeWithDixon is designed specifically for the Ugandan community, ensuring that aspiring developers in Uganda have access to quality mentorship and the tools they need to succeed. The program focuses on empowering students locally, giving them the skills and opportunities to thrive in global tech markets"} title={"Local Impact"} image={ANA}/>
      </div>
   <div className='getStarted'>
<h4 className='getStarted-title'>GET STARTED</h4>
<h4 className='reduce-text'>Be part of a growing network of aspiring developers and tech professionals.</h4>
  <button onClick={start} className='start-here'>Start Here</button>
      <img className='shipment-image' src={TruckerMap}/>
   </div>
<Footer22/>
  
    </div>
  );
}

export default TopContent;
