import React from 'react'
import Logo from "../assets/logo.svg"
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

function HeaderMobile() {
  return (
    <div className='new-header'>
        <Link to="/">
<div className='back-home-section'>
<ArrowLeftOutlined className='new-ic'/>
<p className='backhome-text'>Home</p>
</div>
</Link>
    </div>
  )
}

export default HeaderMobile