import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AWSContext = createContext();

export const useAWS = () => useContext(AWSContext);

export const AWSProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [trucks, setTrucks] = useState([]);
  const [allTrucks, setAllTrucks] = useState([]);
  const [requests, setRequest] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [non_user_requests, setNon_user_reqs] = useState([]);

  const endpoint = 'https://navis-api.onrender.com'; 

  const fetchDeliveriesFromAPI = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${endpoint}/deliveries`);
      const data = await response.json();
      setDeliveries(data);
    } catch (error) {
      console.error("Error fetching deliveries:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchNonUserDeliveries = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${endpoint}/non_user_requests`);
      const data = await response.json();
      setNon_user_reqs(data);
    } catch (error) {
      console.error("Error fetching non-user requests:", error.message);
    } finally {
      setLoading(false);
    }
  };

  
  const fetchDriversFromAPI = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${endpoint}/drivers?company=${user?.company}`);
      // Check if the response is not JSON
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Response: ${errorText}`);
      }
      const data = await response.json();
      setDrivers(data);
    } catch (error) {
      console.error("Error fetching drivers:", error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const updateDeliveryStatus = async (uid, status) => {
    try {
        const response = await fetch(`${endpoint}/non_user_requests/${uid}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status }),
        });
        if (!response.ok) {
            throw new Error('Failed to update status');
        }
    } catch (error) {
        console.error("Error updating delivery status:", error);
    }
};

//rewriting new assigmentCode
const newAssigments = async (uid, plate)=>{
console.log("driver id: ", uid, "truck number plate: ", plate)
try {
  const response = await fetch(`${endpoint}/drivers/${uid}`,{
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/json',
    },
    body:JSON.stringify({plate})
  })

  if (!response.ok) {
    throw new Error('Failed to update status');
}

} catch (error) {
  console.log("error updating driver: ", error)
}

}


  const saveNonUserRequests = async (reqData) => {
    setLoading(true);
    try {
      await fetch(`${endpoint}/saveNonUserRequests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });
    } catch (error) {
      console.error("Error saving request data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const saveDriverDataToAPI = async (driverData) => {
    setLoading(true);
    try {
      await fetch(`${endpoint}/saveDriverData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(driverData),
      });
    } catch (error) {
      console.error("Error saving driver data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTrucksFromAPI = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${endpoint}/trucks?company=${user?.company}`);
      const data = await response.json();
      setTrucks(data);
    } catch (error) {
      console.error("Error fetching trucks:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllTrucks = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${endpoint}/trucks`);
      const data = await response.json();
      setAllTrucks(data);
    } catch (error) {
      console.error("Error fetching all trucks:", error.message);
    } finally {
      setLoading(false);
    }
  };


  const fetchAssignmentsFromDynamoDB = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${endpoint}/all_assigments`);
      const data = await response.json();
      setAssignments(data);
    } catch (error) {
      console.error("Error fetching all trucks:", error.message);
    } finally {
      setLoading(false);
    }
  };


  const saveAssignmentToDynamoDB = async (assignmentData) => {
    setLoading(true);
    try {
      await fetch(`${endpoint}/assignments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(assignmentData),
      });
    } catch (error) {
      console.error("Error saving assignment data:", error.message);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchAssignments = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${endpoint}/assigments`);
      const data = await response.json();
      setAllTrucks(data);
    } catch (error) {
      console.error("Error fetching all trucks:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const saveTruckDataToAPI = async (truckData) => {
    setLoading(true);
    try {
      await fetch(`${endpoint}/saveTruckData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(truckData),
      });
    } catch (error) {
      console.error("Error saving truck data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const loginUser = async (username, password) => {
    setLoading(true);
    try {
        const response = await fetch(`${endpoint}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error(`HTTP error! Status: ${response.status}, Response: ${errorText}`);
            return null;
        }

        const data = await response.json();
        setUser(data);
        return data;
    } catch (error) {
        console.log("login error:", error.message);
        return null;
    } finally {
        setLoading(false);
    }
};


  const uploadImageToS3 = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${endpoint}/upload`, {
        method: 'POST',
        body: formData,
        mode:"cors"
      });
      const data = await response.json();
      return data.imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
};
  const registerUser = async (username, email, company, password, accountType, imageUrl) => {
    setLoading(true);
    try {
      // const imageUrl = await uploadImageToS3(imageFile);
      const userData = { username, email, company, password, accountType, imageUrl };
      await fetch(`${endpoint}/register`, {
        method: 'POST',
        mode:"cors",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
      setUser(userData);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    loading,
    user,
    trucks,
    allTrucks,
    requests,
    drivers,
    assignments,
    deliveries,
    non_user_requests,
    fetchDeliveriesFromAPI,
    fetchNonUserDeliveries,
    fetchDriversFromAPI,
    updateDeliveryStatus,
    saveNonUserRequests,
    saveDriverDataToAPI,
    fetchTrucksFromAPI,
    fetchAllTrucks,
    saveTruckDataToAPI,
    loginUser,fetchAssignments,
    registerUser,
    fetchAssignmentsFromDynamoDB,
    saveAssignmentToDynamoDB,
    uploadImageToS3,
    newAssigments
  };

  return <AWSContext.Provider value={value}>{children}</AWSContext.Provider>;
};
