import React, { useState } from 'react';
import './courses.css';
import Header from './Header';
import Footer22 from "./Footer22";
import { Modal, Input, message as AntMessage, Spin } from 'antd';
import EmailService from './EmailService';

const coursesData = [
  {
    title: "Fullstack Development",
    description: "Learn to build dynamic web applications from scratch, that can be extended to desktop as well.",
    topics: ["JavaScript, HTML, CSS", "React Js, JSX", "Node.js", "MongoDB", "Express, Axios"],
    instructor: "Dixon Muneza",
    image: "https://www.spec-india.com/wp-content/uploads/2020/06/Full_Stack.png",
    pricing: "1,500,000UGX",
    period: "144 Hours"
  },
  {
    title: "Mobile App Development",
    description: "Create robust mobile applications for Android and iOS and any other platform that supports JS.",
    topics: ["React Native", "AWS", "Material UI", "RESTful API Development & Integration"],
    instructor: "Dixon Muneza",
    image: "https://images.spiceworks.com/800x400/wp-content/uploads/2023/10/12122423/mobile-app-development.jpg",
    pricing: "2,000,000UGX",
    period: "240 hours"
  },
  {
    title: "WordPress Development",
    description: "Master WordPress for website building and content management, and quickly start a web development business of your own.",
    topics: ["Introduction to PHP & Scripting", "WordPress Basics, Setting up domains", "Custom Themes", "Custom Plugins", "FTP"],
    instructor: "Dixon Muneza",
    image: "https://webultrasolution.com/blog/wp-content/uploads/2024/01/3907.jpg",
    pricing: "1,000,000UGX",
    period: "96 hours"
  },
];

const Courses = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const showModal = (course) => {
    setSelectedCourse(course);
    setIsModalVisible(true);
  };

  const handleSendEmail = () => {
    if (!userName || !userEmail || !userMessage) {
      AntMessage.error('Please fill in all the fields', {
        style: { transform: 'translateY(-50%)' }, // Center message vertically
      });
      return;
    }

    setLoading(true); // Start loading

    // Send the email
    EmailService({
      userName,
      userEmail,
      userMessage,
      courseName: selectedCourse.title,
    })
      .then(() => {
        AntMessage.success('Message sent successfully!', {
          style: { top: '-50%', transform: 'translateY(-50%)' }, // Center message vertically
        });
        setIsModalVisible(false);
        setUserName('');
        setUserEmail('');
        setUserMessage('');
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        AntMessage.error('Failed to send message.', {
          style: { top: '50%', transform: 'translateY(-50%)' }, // Center message vertically
        });
      })
      .finally(() => {
        setLoading(false); // End loading
      });
  };

  return (
    <div className='courses'>
      <Header />
      <div className="courses-container-2">
        <h2 className='courses-title'>Available Courses</h2>
        <div className="courses-grid">
          {coursesData.map((course, index) => (
            <div key={index} className="course-card" onClick={() => showModal(course)}>
              <img src={course.image} alt={`${course.title}`} className="course-image" />
              <div className="course-content">
                <h3 className='course-title'>{course.title}</h3>
                <p className='course-desc'>{course.description}</p>
                <p className='course-pricing-'>Pricing: <strong className='course-pricing'>{course.pricing}</strong></p>
                <p className='course-pricing- martha'>Duration: <strong className='course-pricing'>{course.period}</strong></p>
                <h4 className='course-topic-title'>Topics Covered:</h4>
                <ul>
                  {course.topics.map((topic, idx) => (
                    <li key={idx}><p className='course-topic'>{topic}</p></li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        title={`Contact us about the ${selectedCourse ? selectedCourse.title : ''}`}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSendEmail}
        okButtonProps={{ disabled: loading }} // Disable button while loading
      >
        {loading ? (
          <Spin tip="Sending..." />  // Show spinner while loading
        ) : (
          <>
            <Input
              placeholder="Your Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              style={{ marginBottom: 10 }}
            />
            <Input
              placeholder="Your Email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              style={{ marginBottom: 10 }}
            />
            <Input.TextArea
              placeholder="Your Message"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              rows={4}
              style={{ marginBottom: 10 }}
            />
          </>
        )}
      </Modal>

      <Footer22 />
    </div>
  );
};

export default Courses;
