import React from 'react'
import "./footer22.css"

function Footer22() {
  return (
    <div className='footer22_main'>



<div className="footer22">
  <div className="contain">
  <div className="col">
    <h1>useful Links</h1>
    <ul>
      <li>Courses</li>
      <li>My Story</li>
      <li>Faqs</li>
      <li>Pricing</li>
      {/* <li>Get in touch</li> */}
    </ul>
  </div>
  <div className="col">
    <h1>Tools</h1>
    <ul>
      <li>
        <a className='ft_links' href='https://kafka.apache.org/'>
        Kafka
        </a>
       
        </li>
      <li>
      <a className='ft_links'

         href="https://en.wikipedia.org/wiki/JavaScript">
        JavaScript

        </a>
        
        </li>
      <li>
        <a className='ft_links'
         href="https://en.wikipedia.org/wiki/React_(JavaScript_library)">
        React Js

        </a>
        
        </li>
      <li>
      <a className='ft_links'   
        href="https://en.wikipedia.org/wiki/Node.js">
        NodeJs
        </a>
        </li>
      <li>
      <a className='ft_links'
 
        href="https://en.wikipedia.org/wiki/W3Schools">
        W3Schools

        </a>        
        </li>
    </ul>
  </div>
  <div className="col">
    <h1>More Tools</h1>
    <ul>
      <li>
      <a className='ft_links'
 
        href="https://en.wikipedia.org/wiki/Android_Studio">
        Android Studio

        </a>        
        </li>
      <li>
      <a className='ft_links'

        href="https://en.wikipedia.org/wiki/Java_(programming_language)">
        Java
        </a>
        
        
        </li>
      <li>
      <a className='ft_links'
 
        href="https://en.wikipedia.org/wiki/Visual_Studio_Code">
        VSCode
        </a>
        
        
        </li>
      <li>
      <a className='ft_links'

        href="https://render.com/">
        Render
        </a>        
        </li>
      <li>
      <a className='ft_links'

href="https://vercel.com/">
Vercel
</a>
        
        </li>
    </ul>
  </div>
  <div className="col">
    <h1>Resources</h1>
    <ul>
      <li>Git </li>
      <li>GitHub</li>
      <li>Firebase</li>
      <li>React Native</li>
      <li>Envato</li>
    </ul>
  </div>
  <div className="col">
    <h1>Support</h1>
    <ul>
      <li>
        <a className='ft_links'>
        Email me
            
        </a>
        
        </li>
      <li>
      <a className='ft_links'>
      Reach Out
            
        </a>
        
        </li>
      <li>
      <a className='ft_links'>
      Submit Form
      </a>
        
        </li>
    </ul>
  </div>
  <div className="col social">
    <h1>Social</h1>
    <ul>
    <div className="footer-socials ft_links">
                <i class="fi fi-brands-whatsapp"></i>
                <a href="https://wa.me/+256753239323" className='link-ft' target="_blank">Whatsapp contact</a>
                </div>
                <div className="footer-location ft_links">
                <i class="fi fi-brands-linkedin"></i>
                                <a href="https://www.linkedin.com/in/dixon-muneza-a0a1b510a/" className='link-ft' target="_blank">Muneza Dixon</a>
                    </div>  
                    <div className="footer-location ft_links">
                <i class="fi fi-ss-envelope"></i>
                                <a href="mailto:dixontheworldvsy@gmail.com" className='link-ft' target="_blank">dixontheworldvsy@gmail.com</a>
                    </div>            
                       </ul>
  </div>
<div className="clearfix"></div>
</div>
</div>



    </div>
  )
}

export default Footer22
