import emailjs from 'emailjs-com';
import { Modal, Input, message as AntMessage } from 'antd';

function EmailService({ userName, userMessage, userEmail, courseName }) {

  const templateParams = {
    to_name: userName,
    to_email: userEmail,
    subject: 'Hello from EmailJS',
    message: userMessage,
    course:courseName
  };
  
  return Promise.all([
    emailjs.send(
      'service_czhamwz',
      'template_5lximrm',
      templateParams,
      'KSkFX4Jhh3wpjhiCY'
    ).then((response) => {
      AntMessage.success('Message sent successfully!');

      console.log('SUCCESS!', response.status, response.text);
    }, (err) => {
      console.error('FAILED...', err);
    })
  ]);
}


export default EmailService;
